import { put, select, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { call } from 'redux-saga-test-plan/matchers';
import * as constants from './constants';
import { login, logout, requestResetPassword } from '../../api/user';
import { checkToken, registerUserWithDetails, resetPasswordNonAuth } from '../../api/auth';
import { actionStatus, getError, getUserInformationFromToken, statusAction } from '../utils';
import { generateId } from '../../helpers/utils';
import { addNotification } from '../ui/notifications/actions';
import { setAuthentication } from './actions';
import { getIsAuthenticated } from './selectors';
import { loadUserInformation } from '../user/actions';

function* handleLogin({ userLogin, password, recaptchaValue }) {
  yield put(statusAction(constants.LOGIN, actionStatus.START));
  try {
    const { data } = yield call(login, userLogin, password, recaptchaValue);

    // save token
    yield localStorage.setItem('accessToken', data.access);
    yield localStorage.setItem('refreshToken', data.refresh);

    yield put(loadUserInformation());
    yield put(statusAction(constants.LOGIN, actionStatus.SUCCESS));
    yield put(setAuthentication(true));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.LOGIN, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.login.${error}`
      })
    );

    yield put(setAuthentication(false));
  }
}

function* handleLogout({ url }) {
  yield put(statusAction(constants.LOGOUT, actionStatus.START));
  try {
    yield logout();
    yield put(setAuthentication(false));

    // removes the access & refreshtoken from localstore
    yield localStorage.removeItem('accessToken');
    yield localStorage.removeItem('refreshToken');

    yield put(statusAction(constants.LOGOUT, actionStatus.SUCCESS));
    if (url) {
      yield put(push(url));
    }
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.LOGOUT, actionStatus.ERROR, {
        message: error
      })
    );
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.logout.${error}`
      })
    );
  }
}

function* handleCheckAuthentication() {
  const accessToken = localStorage.getItem('accessToken');
  const refreshToken = localStorage.getItem('refreshToken');
  const isAuthenticated = yield select(getIsAuthenticated);

  if (refreshToken && accessToken) {
    // always load user information
    yield put(loadUserInformation());

    if (!isAuthenticated) {
      yield put(setAuthentication(true));
    }
  } else if (isAuthenticated) {
    yield put(setAuthentication(false));
  }
}

function* handleRequestPasswordReset({ values }) {
  yield put(statusAction(constants.REQUEST_PASSWORD_RESET, actionStatus.START));
  try {
    yield requestResetPassword(values);

    yield put(statusAction(constants.REQUEST_PASSWORD_RESET, actionStatus.SUCCESS));
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message: 'form.request_success'
      })
    );
  } catch (err) {
    window.grecaptcha.reset();
    const error = getError(err);
    yield put(
      statusAction(constants.REQUEST_PASSWORD_RESET, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.auth.${error}`
      })
    );
  }
}

function* handleCheckTokenCode({ value }) {
  yield put(statusAction(constants.CHECK_TOKEN_CODE, actionStatus.START));
  try {
    const { data } = yield checkToken(value);
    yield put(statusAction(constants.CHECK_TOKEN_CODE, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.CHECK_TOKEN_CODE, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.auth.${error}`
      })
    );
  }
}

function* handleResetPasswordNonAuth({ values, auth }) {
  yield put(statusAction(constants.RESET_PASSWORD_NON_AUTH, actionStatus.START));
  try {
    yield resetPasswordNonAuth(values, auth);
    yield put(statusAction(constants.RESET_PASSWORD_NON_AUTH, actionStatus.SUCCESS));

    yield put(push('/login'));
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message: 'form.success'
      })
    );
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.RESET_PASSWORD_NON_AUTH, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.user.${error}`
      })
    );
  }
}

function* handleRegisterUser({ values, auth, recaptchaValue }) {
  yield put(statusAction(constants.REGISTER, actionStatus.START));
  try {
    const { data } = yield resetPasswordNonAuth(values, auth, recaptchaValue);
    yield put(statusAction(constants.REGISTER, actionStatus.SUCCESS));

    // get user information from token
    const user = getUserInformationFromToken(data.access);

    const { id, ...filteredUser } = user;
    const userData = {
      ...filteredUser,
      ...values
    };

    yield registerUserWithDetails(userData, user.id, data.access);
    yield put(statusAction(constants.REGISTER, actionStatus.SUCCESS));
    yield put(push('/login'));
    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.SUCCESS,
        message: 'form.register_success'
      })
    );
  } catch (err) {
    window.grecaptcha.reset();
    const error = getError(err);
    yield put(
      statusAction(constants.REGISTER, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.register.${error}`
      })
    );
  }
}

export function* watchLoadOrders() {
  yield takeLatest(constants.LOGIN, handleLogin);
  yield takeLatest(constants.LOGOUT, handleLogout);
  yield takeLatest(constants.CHECK_AUTHENTICATION, handleCheckAuthentication);
  yield takeLatest(constants.REQUEST_PASSWORD_RESET, handleRequestPasswordReset);
  yield takeLatest(constants.CHECK_TOKEN_CODE, handleCheckTokenCode);
  yield takeLatest(constants.RESET_PASSWORD_NON_AUTH, handleResetPasswordNonAuth);
  yield takeLatest(constants.REGISTER, handleRegisterUser);
}
