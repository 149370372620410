import { DialCodes, MachineTypes, NotifyIcon, SensorTypes } from 'web-components';
import { getIn } from 'formik';

import React from 'react';
import { ROLE_NO_ACCESS } from 'attrs/products';
import { ERROR, IN_PROGRESS, INFO, INITIAL, SUCCESS, WARNING } from '../attrs/status';
import { ROLES } from '../attrs/roles';
import { NOTIFICATION_COLORS } from '../attrs/colors';

const generateId = () => (new Date().getTime() + Math.random()).toString();

const isSuccess = status => status === SUCCESS;
const isLoading = status => status === IN_PROGRESS;
const isError = status => status === ERROR;

const defaultCheckOrder = [IN_PROGRESS, ERROR, INITIAL, SUCCESS];

const getCommonStatus = (parts, checkOrder = defaultCheckOrder) => {
  for (let i = 0; i < checkOrder.length; i += 1) {
    const status = checkOrder[i];
    if (parts.includes(status)) {
      return status;
    }
  }

  return null;
};

// get common ground of different states to prevent rendering without ready data
// e.g. if any status is "in progress", common status will be "in progress"
const getCommonLoadingState = parts => {
  const getStatus = entry => (entry ? entry.status : null);
  const commonStatus = getCommonStatus(parts.map(getStatus), defaultCheckOrder);

  return {
    status: commonStatus,
    errors: parts.filter(part => part.status === commonStatus).map(part => part.errors)
  };
};

/**
 * Check if loading state is IN_PROGRESS status
 * @param loadingState
 * @returns {boolean}
 */
const isLoadingStatePending = loadingState => !isLoading(loadingState.status);

const getUserRole = role => ROLES.find(item => item.value === role) || ROLES[0];

export const getUserRoleByProduct = (roles, product) => {
  let userProductRole = ROLE_NO_ACCESS;
  if (Array.isArray(roles)) {
    roles.forEach(item => {
      if (item.product === product) {
        userProductRole = item.role;
      }
    });
  }
  return userProductRole;
};

export const isRoleDiffNoAccess = role => role !== ROLE_NO_ACCESS;

const getDialCodeFromCountryCode = value =>
  (DialCodes.find(country => country.code === value) || {}).dial_code || DialCodes[0].dial_code;

const getCountryNameFromCountryCode = (languague, code) => {
  const regionNames = new Intl.DisplayNames([languague], { type: 'region' });

  return regionNames.of(code);
};

const getMachineType = value => MachineTypes.find(item => item.value === value) || MachineTypes[0];

const formatMachineType = type => {
  if (!type) return '---';

  const words = type.split('_');
  const formattedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());

  return formattedWords.join(' ');
};

const getSensorPropsFromType = type => {
  const typeArray = type.split('_');
  const machineIdent = typeArray[0];

  // TODO
  // Just temporary -  We check for the sensor ident number: _00 default
  const sensorIdentNumber = typeArray[typeArray.length - 1];
  const withSensorIdent = sensorIdentNumber !== '00';

  const slicedArray = typeArray.slice(1, typeArray.length - 1);
  const machineSensorType =
    SensorTypes[machineIdent] && SensorTypes[machineIdent][slicedArray.join('_')]
      ? SensorTypes[machineIdent][slicedArray.join('_')]
      : null;

  const defaultProps = machineSensorType || SensorTypes.unknown;
  const subIdentProps = ((machineSensorType || {}).subIdent || {})[sensorIdentNumber] || {};

  const props = withSensorIdent && machineSensorType ? { ...defaultProps, ...subIdentProps } : { ...defaultProps };

  return {
    ...props,
    type,
    name: machineSensorType ? `sensors.${machineIdent}.${slicedArray.join('_')}` : 'sensors.unknown'
  };
};

const getChangeNumberHandler = setFieldValue => name => event => {
  const num = parseInt(event.target.value, 10);
  const value = Number.isNaN(num) ? undefined : num;

  return setFieldValue(name, value);
};

const parseFloatDefault = value => {
  const num = parseFloat(value);
  return Number.isNaN(num) ? undefined : num;
};

const getChangeFloatHandler = setFieldValue => name => event => {
  const value = parseFloatDefault(event.target.value);

  return setFieldValue(name, value);
};

const getChangeSensorTypeHandler = setFieldValue => name => event => {
  const { unit, period } = getSensorPropsFromType(event.target.value);
  setFieldValue(`${name}.type`, event.target.value);
  setFieldValue(`${name}.units`, unit);
  setFieldValue(`${name}.period`, period);
};

const getChangeDataTypeHandler = setFieldValue => (field, value) => {
  if (value === 0) {
    setFieldValue(`${field}.range.min.value`, undefined);
    setFieldValue(`${field}.range.max.value`, undefined);
  } else {
    setFieldValue(`${field}.range.min.source`, null);
    setFieldValue(`${field}.range.max.source`, null);
  }
};

const getFormikError =
  ({ errors, touched }) =>
  name =>
    getIn(errors, name) && getIn(touched, name);

const getFormikHelperText =
  ({ errors, t }) =>
  name => {
    const error = getIn(errors, name);

    if (error) {
      return t(`form.validate.${error}`);
    }

    return '';
  };

const isValidNumber = value => typeof value === 'number';

const getListWithoutDefaultValue = list => list.filter(data => data.value !== 'UNKNOWN');

const isNullOrUndefined = value => value === null || value === undefined;

const isNullUndefinedOrEmpty = value => value === null || value === undefined || value === '';

const numberOrEmpty = value => {
  if (typeof value === 'number') {
    return value;
  }

  return '';
};

const getDefaultFieldValue = (value, defaultValue = '') => (!isNullOrUndefined(value) ? value : defaultValue);

const getInitials = name => {
  if (!name) return '';
  const nameSplit = name.split(' ');
  let initials = '';

  if (nameSplit.length === 1) {
    initials = name.charAt(0);
  } else {
    let firstInitial = '';
    let lastInitial = '';
    nameSplit.forEach((item, key) => {
      if (key === 0) {
        firstInitial = item.charAt(0);
      }
      if (nameSplit.length - 1 === key) {
        lastInitial = item.charAt(0);
      }
      initials = `${firstInitial}${lastInitial}`;
    });
  }
  return initials.toLocaleUpperCase();
};

const showSimpleSnackbar = (enqueueSnackbar, message, type) => {
  const variantIcon = {
    [SUCCESS]: (
      <NotifyIcon iconName="checkmark" style={{ color: NOTIFICATION_COLORS[SUCCESS], marginRight: '.5rem' }} />
    ),
    [WARNING]: <NotifyIcon iconName="warning" style={{ color: NOTIFICATION_COLORS[WARNING], marginRight: '.5rem' }} />,
    [ERROR]: <NotifyIcon iconName="error" style={{ color: NOTIFICATION_COLORS[ERROR], marginRight: '.5rem' }} />,
    [INFO]: <NotifyIcon iconName="info" style={{ color: NOTIFICATION_COLORS[INFO], marginRight: '.5rem' }} />
  };

  const createMessage = () => (
    <span style={{ display: 'flex', alignItems: 'center' }}>
      {variantIcon[type]}
      {message}
    </span>
  );

  enqueueSnackbar(createMessage());
};

const justConsole = (...values) => {
  // eslint-disable-next-line no-console
  console.groupCollapsed(`Log - ${new Date().toISOString()}`);
  values.forEach(value => {
    // eslint-disable-next-line no-console
    console.log('%cValue: ', 'color: red;', value);
  });
  // eslint-disable-next-line no-console
  console.groupEnd();
  return true;
};

/**
 * Just return if the environment are in debug mode ("development" environment)
 * @returns {boolean}
 */
const isDebugMode = () => window.configuration.nodeEnv === 'development';

/**
 * Convert file to a string of base64
 * @param {File | Blob} file
 * @returns {Promise<string>} resultString
 */
const fileToBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };

    reader.readAsDataURL(file);
    reader.onerror = reject;
  });

/**
 * Disallows chars and symbols from being typed in number type inputs
 * @param {InputEvent} evt
 * @returns { void } void
 */
const preventTypeNumberDefaultCharacters = evt => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault();

/**
 * Add years to date
 * @param {Date} _date date to be added
 * @param {Date} years years to be added
 * @returns { Date } returns the date in milliseconds plus the number of years
 */
const addYearsToDate = (_date, years = 1) => {
  const date = new Date(_date);

  return date.setFullYear(date.getFullYear() + years);
};

const validateBlankSpace = source => {
  // Regular expression to match spaces
  const invalidChars = / /g;
  // Remove spaces from the input value
  const formatted = source.replace(invalidChars, '');

  // Further validations or formatting can be added here
  return formatted;
};

const validateBlankSpaceAndDot = source => {
  // Regular expression to match spaces
  const invalidChars = /[ .]/g;
  // Remove spaces from the input value
  const formatted = source.replace(invalidChars, '');

  // Further validations or formatting can be added here
  return formatted;
};

const objectToMap = obj => {
  const map = new Map();

  // Get an array of the object's own keys
  const keys = Object.keys(obj || {});

  // Iterate over the keys using a traditional for loop
  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i];
    map.set(key, obj[key]);
  }

  return map;
};

const convertTimeToSeconds = timeString => {
  const regex = /(?:(\d+)h)?(?:(\d+)m)?(?:(\d+)s)?/;
  const match = timeString.match(regex);

  if (!match) {
    throw new Error('Invalid time format');
  }

  const hours = parseInt(match[1] || '0', 10);
  const minutes = parseInt(match[2] || '0', 10);
  const seconds = parseInt(match[3] || '0', 10);

  const totalSeconds = hours * 3600 + minutes * 60 + seconds;
  return totalSeconds;
};

const convertSecondsToTime = totalSeconds => {
  if (totalSeconds < 0) {
    throw new Error('Seconds cannot be negative');
  }

  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  const timeString =
    (hours > 0 ? `${hours}h` : '') + (minutes > 0 ? `${minutes}m` : '') + (seconds > 0 ? `${seconds}s` : '0s');

  return timeString;
};

/**
 * Performs a deep equality check between two objects.
 * Handles `null` and nested structures properly.
 *
 * @param {object|null} obj1 - The first object to compare.
 * @param {object|null} obj2 - The second object to compare.
 * @returns {boolean} - Returns `true` if the objects are deeply equal, otherwise `false`.
 */
const deepEqual = (obj1, obj2) => {
  if (obj1 === obj2) return true;
  if (obj1 == null || obj2 == null) return false;
  if (typeof obj1 !== 'object' || typeof obj2 !== 'object') return false;

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  if (keys1.length !== keys2.length) return false;

  return keys1.every(key => deepEqual(obj1[key], obj2[key]));
};

/**
 * Detects the system type based on the input string using regex patterns.
 *
 * @param {string} inputString - The string to evaluate (gateway id).
 * @returns {string} - Returns the system type: 'linux', 'windows', 'iris_v3', or 'unknown' if no match is found.
 */
function detectSystem(inputString) {
  // Define the regex patterns for each system
  const patterns = {
    windows: /^W.{9}$/, // Starts with W and has a length of 10
    linux: /^L.{9}$/, // Starts with L and has a length of 10
    iris_v3: /^WV3.{9}$/ // Starts with WV3 and has a length of 12
  };

  // Match the input string against each pattern
  for (const [system, regex] of Object.entries(patterns)) {
    if (regex.test(inputString)) {
      return system; // Return the system name if it matches
    }
  }

  // Return 'unknown' if no match is found
  return 'unknown';
}

export {
  generateId,
  getChangeNumberHandler,
  getChangeFloatHandler,
  getChangeSensorTypeHandler,
  isSuccess,
  isLoading,
  isError,
  getCommonLoadingState,
  isLoadingStatePending,
  getDialCodeFromCountryCode,
  getCountryNameFromCountryCode,
  getUserRole,
  formatMachineType,
  getMachineType,
  getFormikError,
  getFormikHelperText,
  getSensorPropsFromType,
  getChangeDataTypeHandler,
  isValidNumber,
  getListWithoutDefaultValue,
  isNullUndefinedOrEmpty,
  isNullOrUndefined,
  getDefaultFieldValue,
  numberOrEmpty,
  getInitials,
  showSimpleSnackbar,
  justConsole,
  isDebugMode,
  fileToBase64,
  preventTypeNumberDefaultCharacters,
  addYearsToDate,
  validateBlankSpace,
  validateBlankSpaceAndDot,
  objectToMap,
  convertTimeToSeconds,
  convertSecondsToTime,
  deepEqual,
  detectSystem
};
