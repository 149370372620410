import { Alert, Box } from '@mui/material';
import T from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { NotifyIcon, NotifyTypo } from 'web-components';
import {
  Api,
  CheckBoxOutlined,
  ChevronLeft,
  ContentPaste,
  DeveloperBoard,
  Error,
  ListAlt,
  Rule,
  SelectAll,
  Timeline
} from '@mui/icons-material';
import { loadMetricsMachineDetails } from '../../../redux/machines/actions';
import { getSelectedMetricMachine } from '../../../redux/machines/selectors';
import BatchRecords from './BatchRecords';
import ErrorCode from './ErrorCode';
import { ContainerMachine, CustomerBreadcrumb, Wrapper } from './MachineDetail.styled';
import MachineDetails from './MachineDetails';
import Plc from './Plc';
import SensorData from './SensorData';
import StaticValue from './StaticValue';
import Status from './StatusData';
import TaskViewer from './Task';
import DeleteMachine from './DeleteMachineModal';
import MenuComponent from '../../../components/Menu';
import AutoConfig from './AutoConfig';
import { PlcType } from '../../../attrs/plcConfigValues';

const labelAmend = 'machines.machine_details';

const MachineDetailTablet = ({ theme, t, machine, plcType }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { customerId } = useParams();

  /**
   * Get selected machine metrics
   * @type {{
   *   "data": {
   *     "auto_config_data_points": number,
   *     "auto_config_available_data_points": number,
   *     "notify_license_allowed_datapoints": number,
   *     "preventive_license_allowed_tasks": number,
   *     "sensor_data_points": number,
   *     "static_data_points": number,
   *     "statuses_data_points": number,
   *     "total_datapoints_by_organization_count": number,
   *     "total_license_available_datapoints": number,
   *     "total_license_available_datapoints_per_machine": number,
   *     "total_license_available_tasks": number,
   *     "total_machine_data_points": number,
   *     "total_machine_tasks": number,
   *     "total_tasks_by_organization_count": number
   * }}}
   */
  const { data: machineMetrics } = useSelector(getSelectedMetricMachine);

  const organizationName = localStorage.getItem('organizationName');
  const organizationIdStorage = localStorage.getItem('organizationId');

  const breadcrumbObj = [
    {
      route: `/customers/${customerId || organizationIdStorage}`,
      name: `${t('machines.machine_details.back').toUpperCase()}`,
      icon: <ChevronLeft />
    },
    { divider: true },
    { route: '/customers', name: `${t('customers.title')}` },
    {
      route: `/customers/${customerId || organizationIdStorage}`,
      name: organizationName
    },
    {
      route: `/customers/${machine.id || ''}`,
      name: machine.name || t('machines.detail.new_machine')
    }
  ];

  const [selectedTab, setSelectedTab] = useState('machine-details');
  const [deleteModal, setDeleteModal] = useState(false);

  const handleDeleteModal = () => {
    setDeleteModal(false);
  };

  const { selectedTab: selectedTabParam, machineId } = useParams();

  const notifyWarning =
    (machineMetrics?.total_machine_data_points || 0) >
    (machineMetrics?.total_license_available_datapoints_per_machine || 0);
  const preventiveWarning =
    (machineMetrics?.total_machine_tasks || 0) > (machineMetrics?.preventive_license_allowed_tasks || 0);

  /**
   * Menu items
   * @type {{
   *    text?: string,
   *    key: string,
   *    icon: any,
   *    badge: number,
   *    isCategory: boolean,
   *    notClickable: boolean,
   *    topDivider: boolean,
   *    action: (function(...[any]=)),
   *    smallText: string,
   *    show: boolean
   *  }[]}
   */
  const menuItems = [
    {
      key: 'machine-details',
      text: t('machines.detail.details.tab'),
      icon: <ListAlt />,
      topDivider: true
    },
    {
      key: 'auto-config',
      text: t('machines.detail.auto_config.tab'),
      badge: `${machineMetrics?.auto_config_data_points || 0}/${
        machineMetrics?.auto_config_available_data_points || 6
      }`,
      icon: <Api />,
      show: plcType.autoconfig_enabled || false
    },
    {
      text: t('products.notify'),
      isCategory: true,
      topDivider: true,
      badge: `${machineMetrics?.total_machine_data_points || 0}/${
        machineMetrics?.total_license_available_datapoints_per_machine || 0
      }`,
      badgeWarning: notifyWarning,
      notClickable: true
    },
    {
      key: 'plc',
      text: t('machines.detail.plc.tab'),
      icon: <DeveloperBoard />
    },
    {
      key: 'sensor-data',
      text: t('machines.detail.sensor_data.tab'),
      icon: <SelectAll />,
      badge: machineMetrics?.sensor_data_points || 0,
      badgeWarning: notifyWarning
    },
    {
      key: 'static-sensor',
      text: t('machines.detail.static_values.tab'),
      icon: <Timeline />,
      badge: machineMetrics?.static_data_points || 0,
      badgeWarning: notifyWarning
    },
    {
      key: 'status',
      text: t('machines.detail.status.tab'),
      icon: <Rule />,
      badge: machineMetrics?.statuses_data_points || 0,
      badgeWarning: notifyWarning
    },
    {
      key: 'error-codes',
      text: t('machines.detail.error_codes.tab'),
      icon: <Error />
    },
    {
      key: 'batch-records',
      text: t('machines.detail.batch_records.tab'),
      icon: <ContentPaste />
    },
    {
      text: t('products.preventive'),
      smallText: t('products.preventive_small'),
      isCategory: true,
      topDivider: true,
      badge: `${machineMetrics?.total_machine_tasks}/${machineMetrics?.preventive_license_allowed_tasks}`,
      badgeWarning: preventiveWarning,
      notClickable: true,
      show: false
    },
    {
      key: 'tasks',
      text: t('machines.detail.tasks.tab'),
      icon: <CheckBoxOutlined />,
      badge: machineMetrics?.total_machine_tasks || 0,
      badgeWarning: preventiveWarning,
      show: false
    }
  ];

  /**
   * Handle selected tab, update selected tab state and push to history.
   * If tab is not available, redirect to machine details
   * @param {string} tabParam selected tab key
   */
  function handleSelectedTab(tabParam) {
    const tabItem = menuItems.find(item => item.key === tabParam);

    let tab = tabParam;
    if (tabItem && tabItem.show === false) {
      tab = 'machine-details';
    }

    setSelectedTab(tab);
    history.push(`/customers/${customerId}/machines/${machine.id || machineId}/${tab}`);
  }

  useEffect(() => {
    dispatch(loadMetricsMachineDetails(machineId));
  }, [dispatch, machineId]);

  useEffect(() => {
    if (selectedTabParam && selectedTab !== selectedTabParam) {
      // Using handleSelectedTab to ensure that the tab is available
      handleSelectedTab(selectedTabParam);
    }
  }, [selectedTabParam, selectedTab, handleSelectedTab]);

  const handleMenuSelect = tab => {
    handleSelectedTab(tab.key);
  };

  const renderContent = () => {
    switch (selectedTab) {
      case 'machine-details':
        return <MachineDetails data-selector="menu-machine-details" machine={machine} plcType={plcType} />;
      case 'auto-config':
        return <AutoConfig data-selector="menu-auto-config" machineId={machine.id} plcType={plcType} />;
      case 'plc':
        return <Plc data-selector="menu-plc" plcType={plcType} />;
      case 'sensor-data':
        return <SensorData data-selector="menu-sensor-data" plcType={plcType} />;
      case 'static-sensor':
        return <StaticValue data-selector="menu-static-sensor" plcType={plcType} />;
      case 'status':
        return <Status data-selector="menu-status" plcType={plcType} />;
      case 'error-codes':
        return <ErrorCode data-selector="menu-error-codes" plcType={plcType} />;
      case 'batch-records':
        return <BatchRecords data-selector="menu-batch-records" dataset plcType={plcType} />;
      case 'tasks':
        return <TaskViewer data-selector="menu-tasks" />;
      default:
        return null;
    }
  };

  return (
    <>
      <ContainerMachine>
        <MenuComponent
          data-selector="machines-menu"
          menuItems={menuItems}
          onMenuSelect={handleMenuSelect}
          initializeSelected={selectedTab}
        />

        <Wrapper type="content-wrapper" style={{ width: '100%', padding: '48px 24px 24px 24px' }} theme={theme}>
          {plcType.plc_type === PlcType.UNKNOWN && <Alert severity="info">{t(`machines.detail.plc_not_filled`)}</Alert>}
          <CustomerBreadcrumb aria-label="breadcrumb" data={breadcrumbObj} />
          <Box sx={{ mx: 0, my: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box>
              <NotifyTypo.Heading1>{machine.name || t('machines.detail.new_machine')}</NotifyTypo.Heading1>
              <NotifyTypo.Subtitle1>{t(`${labelAmend}.subtitle`)}</NotifyTypo.Subtitle1>
            </Box>
            <Box sx={{ display: 'flex', mr: 4, color: '#2BACA4', alignItems: 'center' }}>
              <Box
                sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                onClick={() => setDeleteModal(true)}
              >
                <NotifyIcon fontSize="large" iconName="delete" />
                <NotifyTypo.Body1 sx={{ fontStyle: 'upperCase' }}>{t(`${labelAmend}.delete`)}</NotifyTypo.Body1>
              </Box>
            </Box>
          </Box>

          {renderContent()}
        </Wrapper>

        <DeleteMachine
          handleClose={handleDeleteModal}
          open={deleteModal}
          machineId={machineId}
          organizationId={customerId || organizationIdStorage}
        />
      </ContainerMachine>
    </>
  );
};

MachineDetailTablet.propTypes = {
  theme: T.instanceOf(Object).isRequired,
  t: T.func.isRequired,
  machine: T.shape({
    id: T.string.isRequired,
    name: T.string.isRequired,
    production_line: T.string.isRequired,
    type: T.string.isRequired,
    commission_number: T.string.isRequired,
    enabled_config: T.shape({
      batch: T.bool
    }),
    machine_metrics: T.shape({
      total_machine_tasks: T.number,
      total_machine_data_points: T.number
    })
  }).isRequired,
  plcType: T.shape({
    plc_type: T.string,
    autoconfig_enabled: T.bool
  }).isRequired
};

export { MachineDetailTablet };
