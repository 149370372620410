import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getSensorReducerState } from 'redux/rootSelectors';
import T from 'prop-types';
import SensorDataContent from './SensorDataContent';
import { loadSensor, loadSensorIris } from '../../../../redux/sensor/actions';
import { PlcType } from '../../../../attrs/plcConfigValues';
import { getIrisSensors } from '../../../../redux/sensor/selectors';

const ConnectedSensorDataContent = ({ plcType }) => {
  const dispatch = useDispatch();
  const { loadingSensorsStatus, paginatedSensorList } = useSelector(getSensorReducerState);
  const { loadingIrisSensorsStatus, irisSensorList } = useSelector(getIrisSensors);
  const { machineId } = useParams();
  const [sensorListParam, setSensorListParam] = useState({});
  const [irisSensorListParam, setIrisSensorListParam] = useState([]);

  // Need to do this because selectors will have the last value of the last call
  // E.g.: If change from IRIS V3 to another type, the selector will still have the last value of IRIS V3
  useEffect(() => {
    if (plcType.plc_type === PlcType.IRIS_V3) {
      setSensorListParam({});
      setIrisSensorListParam(irisSensorList);
    } else {
      setSensorListParam(paginatedSensorList);
      setIrisSensorListParam([]);
    }
  }, [paginatedSensorList, irisSensorList, plcType.plc_type]);

  const loadSensors = useCallback(() => {
    if (machineId) {
      if (plcType.plc_type === PlcType.IRIS_V3) {
        dispatch(loadSensorIris(machineId));
      } else {
        dispatch(loadSensor(machineId));
      }
    }
  }, [dispatch, machineId, plcType.plc_type]);

  useEffect(() => {
    loadSensors();
  }, [loadSensors]);

  const sensorList = useMemo(() => {
    const sensors = sensorListParam?.sensors || {};
    return {
      ...sensors,
      iris: irisSensorListParam || []
    };
  }, [sensorListParam?.sensors, irisSensorListParam]);

  return (
    <SensorDataContent
      loadingState={plcType.plc_type === PlcType.IRIS_V3 ? loadingIrisSensorsStatus : loadingSensorsStatus}
      reloadAction={loadSensors}
      data={{ ...sensorListParam, sensors: sensorList }}
      machineId={machineId}
      plcType={plcType}
    />
  );
};

ConnectedSensorDataContent.propTypes = {
  plcType: T.shape({
    plc_type: T.string.isRequired,
    autoconfig_enabled: T.bool
  }).isRequired
};

export default ConnectedSensorDataContent;
