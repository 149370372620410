import React, { useState, useCallback } from 'react';
import T from 'prop-types';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  GATEWAY_CONNECTED,
  GATEWAY_CONNECTION_STATUS,
  GATEWAY_CONNECTION_TIME,
  GATEWAY_NOT_CONNECTED
} from 'attrs/gateways';
import { NotifyIcon } from 'web-components';
import GatewayUpdaterDialog from './GatewayUpdaterDialog';
import { getValueToNullableCell } from 'helpers/stringHandler';
import { formatMachineType } from 'helpers/utils';

const MemoizedGatewayUpdaterDialog = React.memo(GatewayUpdaterDialog);

export default function GatewayInformation({ gatewayId, gatewayDetails }) {
  const { t } = useTranslation();
  const [openUpdateVersionDialog, setOpenUpdateVersionDialog] = useState(false);

  const handleOpenUpdateVersionDialog = useCallback(() => {
    setOpenUpdateVersionDialog(true);
  }, []);

  const handleCloseUpdateVersionDialog = useCallback(() => {
    setOpenUpdateVersionDialog(false);
  }, []);

  const getConnectionStatus = useCallback(lastSeenParam => {
    const lastSeen = moment(lastSeenParam || null);
    const lastSeenDiff = moment().diff(lastSeen, 'minutes');
    return lastSeenDiff <= GATEWAY_CONNECTION_TIME ? GATEWAY_CONNECTED : GATEWAY_NOT_CONNECTED;
  }, []);

  const getLastSignal = useCallback(
    lastSeenParam => {
      const dt = new Date(lastSeenParam);
      return t('gateway_details.gateway_information.last_signal_value', {
        date: dt.toLocaleDateString(),
        time: dt.toLocaleTimeString(),
        interpolation: { escapeValue: false }
      });
    },
    [t]
  );

  return (
    <Box
      sx={{
        overflow: 'hidden',
        backgroundColor: 'white',
        boxShadow: 2,
        borderRadius: '8px',
        width: '100%',
        p: 3
      }}
      data-selector="gateway-information"
    >
      <Stack direction="column" spacing={3}>
        <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={2}>
          <Typography sx={{ fontSize: 16, fontWeight: 'bold' }}>
            {t('gateway_details.gateway_information.title')}
          </Typography>
          <Button
            variant="outlined"
            sx={{ height: '56px' }}
            startIcon={<RefreshIcon sx={{ fontSize: '36px !important' }} />}
            onClick={handleOpenUpdateVersionDialog}
            disabled={!gatewayDetails?.type}
            data-selector="gateway-information-btn-update-version"
          >
            {t('gateway_details.gateway_information.btn_firmware_update')}
          </Button>
        </Stack>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          rowSpacing={3}
          columnSpacing={2}
          sx={{
            '.MuiGrid-item:first-of-type': { paddingLeft: 0 }
          }}
        >
          <Grid item xs={3}>
            <Stack direction="column" spacing={1} justifyContent="flex-start" alignItems="flex-start">
              <Typography component="span" sx={{ fontSize: 12, color: '#7A7D85' }} gutterBottom>
                {t('gateway_details.gateway_information.linked_machines')}
              </Typography>
              <Typography
                component="span"
                sx={{ fontSize: 16 }}
                data-selector="gateway-information-machine-count"
                gutterBottom
              >
                {gatewayDetails?.machine_count || 0}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={3}>
            <Stack direction="column" spacing={1} justifyContent="flex-start" alignItems="flex-start">
              <Typography component="span" sx={{ fontSize: 12, color: '#7A7D85' }} gutterBottom>
                {t('gateway_details.gateway_information.system_version')}
              </Typography>
              <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
                <Typography
                  component="span"
                  sx={{ fontSize: 16 }}
                  data-selector="gateway-information-actual-version"
                  gutterBottom
                >
                  {getValueToNullableCell(gatewayDetails?.actual_version)}
                </Typography>
                {gatewayDetails?.desired_version !== gatewayDetails?.actual_version && (
                  <NotifyIcon
                    iconName="checkmarkFilled"
                    fontSize="medium"
                    style={{ marginTop: '8px' }}
                    data-testid="warning-icon"
                  />
                )}
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={3}>
            <Stack direction="column" spacing={1} justifyContent="flex-start" alignItems="flex-start">
              <Typography component="span" sx={{ fontSize: 12, color: '#7A7D85' }} gutterBottom>
                {t('gateway_details.gateway_information.desired_system_version')}
              </Typography>
              <Typography
                component="span"
                sx={{ fontSize: 16 }}
                data-selector="gateway-information-desired-version"
                gutterBottom
              >
                {getValueToNullableCell(gatewayDetails?.desired_version)}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={3}>
            <Stack direction="column" spacing={1} justifyContent="flex-start" alignItems="flex-start">
              <Typography component="span" sx={{ fontSize: 12, color: '#7A7D85' }} gutterBottom>
                {t('gateway_details.gateway_information.operating_system')}
              </Typography>
              <Typography component="span" sx={{ fontSize: 16 }} data-selector="gateway-information-type" gutterBottom>
                {formatMachineType(gatewayDetails?.type)}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={3} sx={{ pl: '0px !important' }}>
            <Stack direction="column" spacing={1} justifyContent="flex-start" alignItems="flex-start">
              <Typography component="span" sx={{ fontSize: 12, color: '#7A7D85' }} gutterBottom>
                {t('gateway_details.gateway_information.connection_status')}
              </Typography>

              {getConnectionStatus(gatewayDetails?.last_seen) === GATEWAY_CONNECTED ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <NotifyIcon
                    iconName="gatewayConnected"
                    fontSize="medium"
                    htmlColor={GATEWAY_CONNECTION_STATUS[GATEWAY_CONNECTED].color}
                    style={{
                      marginRight: '.5rem'
                    }}
                  />
                  <Typography
                    component="span"
                    sx={{ fontSize: 16 }}
                    data-selector="gateway-information-status-connected"
                    gutterBottom
                  >
                    {t(GATEWAY_CONNECTION_STATUS[GATEWAY_CONNECTED].label)}
                  </Typography>
                </div>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <NotifyIcon
                    iconName="gatewayDesconnected"
                    fontSize="medium"
                    htmlColor={GATEWAY_CONNECTION_STATUS[GATEWAY_NOT_CONNECTED].color}
                    style={{
                      marginRight: '.5rem'
                    }}
                  />
                  <Typography
                    component="span"
                    sx={{ fontSize: 16 }}
                    data-selector="gateway-information-status-not-connected"
                    gutterBottom
                  >
                    {t(GATEWAY_CONNECTION_STATUS[GATEWAY_NOT_CONNECTED].label)}
                  </Typography>
                </div>
              )}
            </Stack>
          </Grid>
          <Grid item xs={3}>
            <Stack direction="column" spacing={1} justifyContent="flex-start" alignItems="flex-start">
              <Typography component="span" sx={{ fontSize: 12, color: '#7A7D85' }} gutterBottom>
                {t('gateway_details.gateway_information.last_signal')}
              </Typography>
              <Typography
                component="span"
                sx={{ fontSize: 16 }}
                gutterBottom
                data-selector="gateway-information-last-seen"
              >
                {getLastSignal(gatewayDetails?.last_seen)}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Stack>

      {/* Render dialog only if open */}
      {openUpdateVersionDialog && (
        <MemoizedGatewayUpdaterDialog
          open={openUpdateVersionDialog}
          handleClose={handleCloseUpdateVersionDialog}
          gatewayId={gatewayId}
          desiredVersion={gatewayDetails?.desired_version}
          serialNumber={gatewayDetails?.serial_number}
          type={gatewayDetails?.type}
          data-selector="gateway-information-dialog-update-version"
        />
      )}
    </Box>
  );
}

GatewayInformation.propTypes = {
  gatewayId: T.string.isRequired,
  gatewayDetails: T.shape().isRequired
};
